<template>
  <div class='notes'>

    <!-- edit icons -->
    <div v-if="loggedIn" class="ma-2">
      <v-row>
        <v-btn outlined elevation="0" color="success" @click="savePage"> Save
          <v-icon right>mdi-content-save-outline</v-icon>
        </v-btn>
        <v-btn outlined elevation="0" 
          color="warning" class="mx-1" 
          @click="previewPage"
          v-if="preview != true">Preview
          <v-icon right>mdi-eye-outline</v-icon>
        </v-btn>
        <v-btn outlined elevation="0" 
          color="warning" class="mx-1" 
          @click="previewPage"
          v-else>Edit
          <v-icon right>mdi-pencil</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-btn outlined elevation="0" color="error" @click="deletePage">Delete
          <v-icon right dark>mdi-delete</v-icon>
        </v-btn>
      </v-row>
    </div>

    <!-- content -->
    <div v-if="loggedIn===true && preview == false">
      <v-textarea full-width rows=20 v-model="contentData"></v-textarea>
    </div>
    <div class="my-4" v-if="loggedIn!==true || preview" >
      <markdown-it-vue-light refs="markIt" :content="contentData" :options="options"/>
    </div>

    <!-- delete dialog -->
    <v-dialog v-model="noteDeleteDialog" persistent width="300">
      <v-card>
        <v-card-title class="text-h5 warning--text">
          Delete this note ?
          <v-spacer></v-spacer>
          <v-btn small fab icon @click="noteDeleteDialog=false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>        
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" outlined @click="deleteNote">
            Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import MarkdownItVueLight from 'markdown-it-vue/dist/markdown-it-vue-light.umd.min.js'
import 'markdown-it-vue/dist/markdown-it-vue-light.css'
import { eventBus } from "../main";

export default {
  components: {
    MarkdownItVueLight
  },
  data() {
    return {
      options:{
        
      },
      contentData: "",
      noteId:NaN,
      loggedIn: false,
      preview:false,
      noteDeleteDialog: false,
    }
  },
  methods:{
    loadPage(){
      if (this.noteId){
      var url = this.$webUrl+"/api/notes/"+this.noteId
        fetch(url)
          .then(async response => {
            const data = await response.json();
            if (!response.ok) {
              // error
              const error = (data && data.message) || response.statusText;              
              // console.error(error)
              return Promise.reject(error);
            }
            //success            
            this.contentData = data.content
            eventBus.$emit('openBookTab', data.book)
          })
          .catch(error => {
            console.error("There was an error!", error);
            this.$router.push({ path: '/notfound' })
          });
      }
      else{
        this.contentData = "# My Notes\n Click any of the notes on the left"
      }
    },
    previewPage(){
      this.preview = !this.preview
    },
    savePage(){
      const requestOptions = {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$token.value
        },
        body: JSON.stringify({
          id: parseInt(this.noteId),
          content: this.contentData
        })
      }
      var url = this.$webUrl+"/api/notes/save"
      fetch(url, requestOptions)
        .then(async response => {
          const data = await response.json();
          if (!response.ok) {
            // error
            const error = (data && data.message) || response.statusText;              
            return Promise.reject(error);
          }
          //success
          this.$root.toast.show({message: 'Note saved. 👍', color: 'success'})
        })
        .catch(error => {
          this.$root.toast.show({message: 'Error saving note 😱', color: 'warning'})
          console.error('[ERROR]', error)
        });
    },
    deletePage(){
      this.noteDeleteDialog=true
    },
    deleteNote(){
      const requestOptions = {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': this.$token.value
        }
      }
      var url = this.$webUrl+"/api/notes/"+this.noteId
      fetch(url, requestOptions)
        .then(async response => {
          const data = await response.json();
          this.noteDeleteDialog=false
          if (!response.ok) {
            // error
            const error = (data && data.message) || response.statusText;              
            return Promise.reject(error);
          }
          //success
          this.$root.toast.show({message: 'Note deleted. 👍', color: 'success'})
          eventBus.$emit('refreshAllBooksAndNotes')
          this.$router.push({ path: '/' })
        })
        .catch(error => {
          this.$root.toast.show({message: 'Error deleting note. 😱', color: 'error'})
          console.error('[ERROR]', error)
        });
    },
    tokenHandler(){
      if (this.$token.value == ''){
        this.loggedIn = false
        this.preview = false
      } else {
        this.loggedIn = true
      }
    }
  },
  watch: {
    $route (){
      // console.log(to, from)
      this.noteId = this.$route.params.id
      this.loadPage()   
    }
  },
  created() {
    this.noteId = this.$route.params.id
    this.loadPage()
    this.tokenHandler()
    eventBus.$on('gotToken', this.tokenHandler);
  },
  beforeDestroy(){
    eventBus.$off('gotToken', this.tokenHandler)
  }
}
</script>

<style>
.theme--dark .markdown-body {
  color: white !important;
}
.theme--dark .markdown-body table * {
  background: rgb(18, 18, 18);
}
.theme--dark .markdown-body pre {
  background-color:  rgb(50, 50, 50);
  color:  rgb(255,255,255);
}
.theme--dark .markdown-body pre *{
  background-color:  rgb(50, 50, 50);
}
.theme--light .markdown-body pre {
  background-color:  rgb(235, 235, 235);
}
.theme--light .markdown-body pre *{
  background-color: rgb(235, 235, 235);
}
</style>